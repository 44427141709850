import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { navigate } from "gatsby";
import Layout from "../../components/Layout";
import Banner from "../../components/Portfolio/Banner";
import PageContent from "../../components/Portfolio/PageContent";
import * as prismic from "@prismicio/client";
import { client as prismicClient } from "../../libs/prismic";
import Loader from "../../components/Loader";
import Hero from "../../components/Hero";
import AccountService from "../../services/AccountService";
import {
  hideAlert,
} from "../../store/reducers/alert";
import {
  actionTypes as dialogActionTypes,
} from "../../store/Dialog/type";
import "../../styles/pages/portfolios.scss";
import {
  getCurrentPrice
} from "../../utils";

const accountService = new AccountService();

const Portfolios = ({location}) => {
  const [portfolioDoc, setPortfolioDoc] = useState();
  const [accounts, setAccounts] = useState([]);
  const [isLoading, setLoading] = useState(false);
  const [opals, setOpals] = useState([]);
  const [currentPrices, setCurrentPrices] = useState({});
  const dispatch = useDispatch();

  const user = useSelector(
    (state) => state?.app?.profile
  );

  const parentAccounts = useSelector(
    (state) => state?.accounts?.parentAccounts
  );

  const params = new URLSearchParams(location.search);
  const addmethod = params.get("addmethod");

  useEffect(() => {
    const _accounts = parentAccounts?.filter(el => el.hasOwnProperty('zipcode'));
    if (addmethod === '1' && _accounts.length > 0) {
      dispatch({ type: dialogActionTypes.SHOW_DIALOG, value: { type: 'dialog_add_payment_account', show: true, payment: null, account: _accounts[_accounts.length - 1] } });
      navigate('/portfolio/')
    }
  }, [addmethod, parentAccounts, dispatch])

  useEffect(() => {
    async function fetchData() {
      try {
        setLoading(true);
        const response = await prismicClient().getSingle("portfolio");
        setPortfolioDoc(response);
        const account = await accountService.getAllAccounts(user._id, true)
        setAccounts(account.filter(el => el.hasOwnProperty('zipcode')));
        const brandResponse = await prismicClient().get({
          predicates: prismic.predicates.at(
            "document.type",
            "opal_fund_partners"
          ),
          orderings: {
            field: "document.first_publication_date",
            direction: "desc",
          },
        });
        let _opals = [];
        let _currentPrices = {};
        for (const brand of brandResponse?.results) {
          for (const opal of brand.data?.opals) {
            _opals.push({ brand, opal });
            let key = `${brand.uid}-${opal.opal_name}`;  
            _currentPrices[key] = await getCurrentPrice(opal);
          }
        };  
        setOpals(_opals);
        setCurrentPrices(_currentPrices);
      } catch (e) {
      } finally {
        setLoading(false);
      }
    }
    fetchData();
    return () => dispatch(hideAlert());
  }, [user?._id, dispatch]);


  return !isLoading ? (
    <Layout>
      <Hero path={'/portfolio'}/>
      <div className="portfolios">
        {accounts.length === 0 ? <Banner content={portfolioDoc} /> : <h2 className="justify-content-center mx-auto mw-1440">Portfolios</h2>}
        <PageContent accounts={accounts} currentPrices={currentPrices} opals={opals}/>
      </div>
    </Layout>
  ) : (
    <Loader />
  );
};

export default Portfolios;
