/* eslint-disable no-console, jsx-a11y/no-static-element-interactions, jsx-a11y/click-events-have-key-events, jsx-a11y/label-has-associated-control*/
import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { navigate } from "gatsby";
import { Row, Col, Button, Image } from "react-bootstrap";
import Loader from "../../../components/Loader";
import clsx from "clsx";
import { Table, Thead, Tbody, Tr, Th, Td } from "../../../components/Table";
import TextInputField from "../../../components/TextInputField";
import {
  getAddress,
  getName,
  getInvestCount,
  getInvestCost,
  getGain,
  getPendingCount,
  getPendingCost,
  numberWithCommas,
} from "../../../utils";
import "../../../styles/components/Portfolio/PageContent/style.scss";


const PortfolioPageContent = ({ accounts, currentPrices, opals }) => {
  const [isLoading, setLoading] = useState(false);
  const [sort, setSort] = useState(true)
  const [gains, setGains] = useState({});
  const [keyword, setKeyword] = useState("");

  const profile = useSelector(
    (state) => state?.app?.profile
  );

  const representativeAccounts = accounts.filter(el => el.type === 'ria')
  // console.log('accounts', accounts.map((e) => {
  //   return {type: e.type, entityName: e.entityName} 
  // }));

  const nonRepresentativeAccounts = accounts
    .filter(el => {
      if (el.type !== 'ria') {
        if (el.type === 'individual' || el.type === 'joint' || el.type === 'sd-ira') {
          return el.legalFirstName.toLowerCase().includes(keyword.toLowerCase()) ||
            el.legalLastName.toLowerCase().includes(keyword.toLowerCase());
        }
        else {
          return el.entityName.toLowerCase().includes(keyword.toLowerCase());
        }
      }
      return false;
    })
    .sort((a, b) => {
      let aName = (a.type === 'individual' || a.type === 'joint' || a.type === 'sd-ira') ? sort ? `${a?.legalFirstName} ${a?.legalLastName}` : `${a?.legalLastName} ${a?.legalFirstName}` : a?.entityName;
      let bName = (b.type === 'individual' || b.type === 'joint' || b.type === 'sd-ira') ? sort ? `${b?.legalFirstName} ${b?.legalLastName}` : `${b?.legalLastName} ${b?.legalFirstName}` : b?.entityName;
      aName = aName.toLowerCase();
      bName = bName.toLowerCase();

      return aName.localeCompare(bName);
    })
  const ria = accounts?.[0]?.type === 'ria'
  const originalNonRiaAccounts = accounts.filter(el => el.type !== 'ria')

  const detailHandler = (accountId) => {
    navigate(`/portfolio/${accountId}`)
  }

  const getGainById = (id) => {
    let gain = gains[id];

    if (!gain) {
      gain = { monthPercent: (0).toFixed(1), monthCost: 0, yearPercent: (0).toFixed(1), yearCost: 0 };
    }

    return gain;
  }

  useEffect(() => {
    async function fetchData() {
      try {
        setLoading(true);
        const _gains = {};
        for (const account of accounts) {
          const gainResult = await getGain(account, ria, opals);
          _gains[account._id] = gainResult;
        }
        setGains(_gains);
      } catch (error) {
      } finally {
        setLoading(false);
      }
    }
    fetchData();
  }, [accounts, opals, ria]);

  return (!isLoading) ? (
    <Row className="justify-content-center mx-auto mw-1440 portfolio_content">
      {representativeAccounts.length > 0 &&
        <>
          <Col md={12} className="title-div">
            Registered Investment Advisor
          </Col>
          <Col md={12}>
            <Table className="ria-table">
              <Thead>
                <Tr>
                  <Th style={{ width: '35%' }}>Registered Investment Advisor</Th>
                  <Th style={{ width: '20%' }}></Th>
                  <Th style={{ width: '20%' }}></Th>
                  <Th style={{ width: '25%' }}>Opalvest Representative</Th>
                </Tr>
              </Thead>
              <Tbody>
                {representativeAccounts.map((account) => {
                  return (
                    <Tr key={account._id} className="nohover">
                      <Td className="portfolio-div">
                        <div className="account-field">
                          <label className="name-label">
                            {`${profile.firstName} ${profile.lastName}`}
                          </label>
                          <label>{account.riaCompany}</label>
                        </div>
                        {/* <Image
                          className="edit-icon"
                          src="/assets/images/icon_edit.svg"
                        /> */}
                      </Td>
                      <Td className="type-div">
                        <div className="d-flex justify-content-between">
                          <label><b>Opal AUM</b></label>
                          <label>${numberWithCommas(originalNonRiaAccounts.reduce((sum, account) => {
                            return sum + getInvestCost(account, ria, currentPrices)
                          }, 0), false)}
                          </label>
                        </div>
                        <div className="d-flex justify-content-between mt-20">
                          <label><b># of Accounts</b></label>
                          <label>{originalNonRiaAccounts.length}</label>
                        </div>
                      </Td>
                      <Td className="verify-div">
                        <div>
                          <label><b>Member Since</b></label>
                        </div>
                        <div>
                          {!account.hasOwnProperty('approvalTemplate') ? <Button>Pending verification</Button> : <label>{new Date(account.created).toLocaleDateString('en-us', { year: "numeric", month: "long" })}</label>}
                        </div>

                      </Td>
                      <Td className="action2-div">

                        <label className="action-name">
                          Jeff Maxon
                        </label>
                        <div className="d-flex align-items-center email-div">
                          <Image
                            className="contact-icon"
                            src="/assets/images/icon_email.svg"
                          />
                          <Button><a href="mailto:jmaxon@opalvest.com" target="_blank">jmaxon@opalvest.com</a></Button>
                        </div>
                        <div className="d-flex phone-div">
                          <Image
                            className="contact-icon"
                            src="/assets/images/icon_phone.svg"
                          />
                          <div className="phone-button"><Button><a target="_blank" href='https://meetings.hubspot.com/jeff-maxon/introductory-call'>Schedule a call</a></Button></div>
                        </div>
                      </Td>
                    </Tr>
                  );
                })}
              </Tbody>
            </Table>
          </Col>
        </>}
      {originalNonRiaAccounts.length > 2 && (
        <Col md={12} style={{ marginBottom: "20px" }}>
          <TextInputField
            variant="filled"
            InputProps={{
              disableUnderline: true,
            }}
            onChange={(e) => setKeyword(e.target.value)}
            value={keyword}
            placeholder="Search by Name"
            className="search-input"
          />
        </Col>
      )}
      {nonRepresentativeAccounts.length > 0 &&
        <>
          <Col md={12} className={clsx("title-div", {
            "d-none": representativeAccounts.length === 0
          })}>
            Investor&nbsp;&nbsp;{nonRepresentativeAccounts.length > 1 && <span onClick={() => setSort(prev => !prev)} className="clickable">{!sort ? "(First Last)" : "(Last, First)"}</span>}
          </Col>
          <Col md={12} className={clsx("second-table", {
            "mt-0": nonRepresentativeAccounts.length > 2
          })}>
            <Table>
              <Thead>
                <Tr>
                  <Th style={{ width: '42%' }}>Investor&nbsp;&nbsp;{nonRepresentativeAccounts.length > 1 && <span onClick={() => setSort(prev => !prev)} className="clickable">{!sort ? "(First Last)" : "(Last, First)"}</span>}</Th>
                  <Th style={{ width: '17%' }}>Assets</Th>
                  <Th style={{ width: '17%' }}>This Month</Th>
                  <Th style={{ width: '17%' }}>This Year</Th>
                  <Th style={{ width: '17%' }}>Pending</Th>
                </Tr>
              </Thead>
              <Tbody>
                {nonRepresentativeAccounts.map((account) => {
                  const count = getInvestCount(account, ria);
                  const cost = getInvestCost(account, ria, currentPrices);
                  const pendingCount = getPendingCount(account, ria);
                  const pendingCost = getPendingCost(account, ria);
                  const { monthPercent, monthCost, yearPercent, yearCost } = getGainById(account._id);
                  return (
                    <Tr key={account._id} onClick={() => {
                      if (count !== 0 || pendingCount !== 0)
                        detailHandler(account._id)
                    }} className={clsx("investor-table", { "nohover": count === 0 && pendingCount === 0 })}>
                      <Td className="portfolio-div">
                        <div className="account-field">
                          <label className="name-label">
                            {getName(account, sort)}
                          </label>
                          <label>{getAddress(account)}</label>
                        </div>
                      </Td>
                      {count === 0 && pendingCount === 0 ? <Td colspan="4"><center>No activity to date</center></Td> : <>
                        <Td>
                          <div className="assets-div">
                            <div>
                              <b>{count !== 0 ? `${count} Opal${count === 1 ? '' : 's'}` : ' '} </b>
                            </div>
                            <div>
                              {count !== 0 ? `$${numberWithCommas(cost, false)}` : ' '}
                            </div>
                          </div>

                        </Td>
                        <Td>
                          <div className="assets-div">
                            <div style={{ color: monthCost === 0 ? 'black' : (monthCost > 0 ? '#1DA775' : '#F06158') }}>
                              <b>{count !== 0 ? `${monthCost >= 0 ? '+' : '-'} ${Math.abs(monthPercent)}%` : ' '}</b>
                            </div>
                            <div>
                              {count !== 0 ? `${monthCost >= 0 ? '+' : '-'} $${numberWithCommas(Math.abs(monthCost), false)}` : ' '}
                            </div>
                          </div>

                        </Td>
                        <Td>
                          <div className="assets-div">
                            <div style={{ color: yearCost === 0 ? 'black' : (yearCost > 0 ? '#1DA775' : '#F06158') }}>
                              <b>{count !== 0 ? `${yearCost >= 0 ? '+' : '-'} ${Math.abs(yearPercent)}%` : ' '}</b>
                            </div>
                            <div>
                              {count !== 0 ? `${yearCost >= 0 ? '+' : '-'} $${numberWithCommas(Math.abs(yearCost), false)}` : ' '}
                            </div>
                          </div>

                        </Td>
                        <Td>
                          <div className="assets-div">
                            <div>
                              <b>{pendingCount !== 0 ? `${pendingCount} Opal${pendingCount === 1 ? '' : 's'}` : ' '} </b>
                            </div>
                            <div>
                              {pendingCount !== 0 ? `$${numberWithCommas(pendingCost, false)}` : ' '}
                            </div>
                          </div>

                        </Td>
                      </>}

                    </Tr>
                  );
                })}
              </Tbody>
            </Table>
          </Col>
        </>}
      {nonRepresentativeAccounts.length === 0 && (
        <>
          <Col md={12} className={clsx("second-table no-result", {
            "mt-0": representativeAccounts.length === 0
          })}>
            <Table>
              <Thead>
                <Tr>
                  <Th style={{ width: '42%' }}>Investor</Th>
                  <Th style={{ width: '17%' }}>Assets</Th>
                  <Th style={{ width: '17%' }}>This Month</Th>
                  <Th style={{ width: '17%' }}>This Year</Th>
                  <Th style={{ width: '17%' }}>Pending</Th>
                </Tr>
              </Thead>
              <Tbody>
                <Tr className="nohover">
                  <Td>{originalNonRiaAccounts.length === 0 ? 'No accounts have been added.' : 'No accounts match your search.'}</Td>
                </Tr>
              </Tbody>
            </Table>
          </Col>
        </>
      )}
    </Row>
  ) : (
    <Loader />
  );
};

export default PortfolioPageContent;
